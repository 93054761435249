import React, { useState } from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import { GatsbyImage, withArtDirection, getImage } from "gatsby-plugin-image"

// Components
import Layout from "@components/layout"
import Seo from "@components/seo"
import Container from "@components/container"
import Section from "@components/section"
import StaticHero from "@components/staticHero"
import DownloadItem from "@components/DownloadItem"
import Callout from "@components/callout"
import MyWavVideoSection from "@components/MyWavVideoSection"
import VideoGallery from "@components/videoGallery"
import LearnMore from "@components/learnMore"
import { Heading2 } from "@styles/ui"
import PrimaryCta from "@components/PrimaryCta"

const PatientResourcesPage = ({ location, data }) => {
  const [currentGallery, setCurrentGallery] = useState(null)

  const supImage = withArtDirection(getImage(data.collageDesktop), [
    {
      media: "(max-width: 768px)",
      image: getImage(data.collageMobile),
    },
  ])

  const videoGalleryJennie = [
    {
      wistiaId: "5ypuy1it1c",
      title: "Uncovering her Idiopathic Hypersomnia",
      description:
        "Jennie recalls her journey to an Idiopathic Hypersomnia diagnosis.",
      thumbnail: data.vidJennie1,
      thumbnailSmall: data.vidJennie1Small,
      alt: "Patient Jennie talking about her IH diagnosis",
      id: 0,
    },
    {
      wistiaId: "t7w25f5jby",
      title: "Getting started with XYWAV",
      description: "Jennie shares her treatment story.",
      thumbnail: data.vidJennie2,
      thumbnailSmall: data.vidJennie2Small,
      alt: "Patient Jennie shares how she got started on XYWAV®",
      id: 1,
    },
    {
      wistiaId: "zcxb7qtoef",
      title: "Setting a nighttime routine with XYWAV",
      description: (
        <>
          Jennie shares what its like taking twice&#8209;nightly XYWAV to treat
          her Idiopathic Hypersomnia.
        </>
      ),
      thumbnail: data.vidJennie3,
      thumbnailSmall: data.vidJennie3Small,
      alt: "Patient Jennie's nighttime routine with XYWAV®",
      id: 2,
    },
    {
      wistiaId: "gu6an6cutr",
      title: (
        <>
          Working with JazzCares<sup>&reg;</sup>
        </>
      ),
      description: "Jennie talks more about JazzCares and other resources.",
      thumbnail: data.vidJennie4,
      thumbnailSmall: data.vidJennie4Small,
      alt: "Patient Jennie's story with JazzCares®",
      id: 3,
    },
  ]

  const videoGalleryDiana = [
    {
      wistiaId: "tek124hiod",
      title: "Diana's personal journey",
      description: "Diana shares her XYWAV story.",
      alt: "Patient Diana shares her XYWAV® story",
      thumbnail: data.vidDiana1,
      thumbnailSmall: data.vidDiana1Small,
      id: 0,
    },
    {
      wistiaId: "4q9xe20fta",
      title: "Diana & Dr. Meskill on identifying Idiopathic Hypersomnia",
      description:
        "Asking questions about how Diana feels day and night helps Dr. Meskill pinpoint the symptoms of Idiopathic Hypersomnia.",
      alt: "Patient Diana and Dr. Meskill discuss symptoms of Idiopathic Hypersomnia",
      thumbnail: data.vidDiana2,
      thumbnailSmall: data.vidDiana2Small,
      id: 1,
    },
    {
      wistiaId: "cvpdirh8og",
      title: "Diana & Dr. Meskill on sleep inertia",
      description: (
        <>
          Diana tells Dr. Meskill that she has a lot of trouble getting up in
          the morning&mdash;even with multiple alarms.
        </>
      ),
      alt: "Patient Diana and Dr. Meskill discuss sleep inertia ",
      thumbnail: data.vidDiana3,
      thumbnailSmall: data.vidDiana3Small,
      id: 2,
    },
    {
      wistiaId: "37olgdk4j9",
      title: "Diana & Dr. Meskill on Idiopathic Hypersomnia symptoms",
      description:
        "Diana thinks beyond excessive daytime sleepiness to explore her additional IH symptoms.",
      alt: "Patient Diana and Dr. Meskill discuss Excessive Daytime Sleepiness (EDS)",
      thumbnail: data.vidDiana4,
      thumbnailSmall: data.vidDiana4Small,
      id: 3,
    },
    {
      wistiaId: "139dj201gv",
      title: "Diana & Dr. Meskill on treatment goals",
      description: (
        <>
          Dr. Meskill recommends XYWAV, the only FDA&#8209;approved option for
          adults with IH.
        </>
      ),
      alt: "Patient Diana and Dr. Meskill discuss XYWAV®",
      thumbnail: data.vidDiana5,
      thumbnailSmall: data.vidDiana5Small,
      id: 4,
    },
    {
      wistiaId: "vt3h5j8bg7",
      title: "Diana & Dr. Meskill discuss XYWAV",
      description:
        "See how Dr. Meskill describes XYWAV and helps Diana get started on her treatment journey.",
      alt: "Patient Diana and Dr. Meskill discuss XYWAV®",
      thumbnail: data.vidDiana6,
      thumbnailSmall: data.vidDiana6Small,
      id: 5,
    },
  ]

  return (
    <Layout
      location={location}
      type="ih"
      isiBgColour="white"
      footerBgColour="white"
    >
      <Seo
        title="Idiopathic Hypersomnia Resources & Patient Support Videos | XYWAV® for IH"
        description="Find resources and videos to help answer your questions about Idiopathic Hypersomnia (IH) and XYWAV® (calcium, magnesium, potassium, and sodium oxybates). See full Prescribing Information & BOXED Warning about serious side effects, CNS depression, and misuse and abuse."
        location={location}
      />
      <StaticHero
        eyebrow="Resources & Videos"
        imageData={data.image}
        alt="woman smiling"
        bgImgSm
        gradient="purple"
      >
        Discover more about XYWAV for adults with Idiopathic Hypersomnia (IH)
      </StaticHero>

      {/* Resource download cards */}
      <Section bgColour={tw`bg-lilac`}>
        <Container>
          <img
            src={"/images/xywav-logo-large-callout.svg"}
            alt=""
            tw="hidden lg:(block absolute bottom-[-80px] right-[-210px] w-[530px])"
          />
          <h2 css={[Heading2, tw`mb-7 lg:(mb-11 mt-5)`]}>
            Download these resources to help you along your Idiopathic
            Hypersomnia journey
          </h2>
          <DownloadItem downloads={downloadDataTop} />
        </Container>
      </Section>

      {/* Video gallery - Jennie */}
      <Section>
        <Container>
          <h2 css={[Heading2, tw`text-studio mb-2.5 leading-[1]`]}>
            Meet Jennie, <br tw="md:hidden" />
            <span tw="text-merlin text-xl lg:text-4xl">
              a real patient and XYWAV IH Ambassador
            </span>
          </h2>
          <p tw="mb-7 lg:mb-12">
            Watch as Jennie shares her journey from an Idiopathic Hypersomnia
            diagnosis to treatment with XYWAV.
          </p>
          <VideoGallery
            videos={videoGalleryJennie}
            location={location}
            sectionId="jennie"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="1"
          />
        </Container>
      </Section>

      {/* Resource download cards */}
      <Section bgColour={tw`bg-lilac`}>
        <img
          src={"/images/xywav-logo-large-callout.svg"}
          alt=""
          tw="hidden lg:(block absolute bottom-[-80px] right-[-210px] w-[530px])"
        />
        <Container>
          <h2 css={[Heading2, tw`mb-7 lg:(mb-11 mt-5)`]}>
            Tools for tracking your Idiopathic Hypersomnia symptoms
          </h2>
          <DownloadItem downloads={downloadDataBottom} />
        </Container>
      </Section>

      {/* Webinar callout */}
      <Callout heading={callout.heading} cta={callout.cta}>
        Check out our webinar series presented by experts in Idiopathic
        Hypersomnia and treatment with XYWAV. Programs cover a variety of topics
        and even include a live Q&A!
      </Callout>

      {/* myWAV  */}
      <MyWavVideoSection
        thumbnail={data.myWavVidThumb}
        currentGallery={currentGallery}
        setCurrentGallery={setCurrentGallery}
        galleryId="2"
      />

      {/* Video gallery - Diana */}
      <Section>
        <Container>
          <h2 css={[Heading2, tw`text-studio mb-2.5 leading-[1]`]}>
            Meet Diana, <br tw="md:hidden" />
            <span tw="text-merlin text-xl lg:text-4xl">
              a real patient and XYWAV IH Ambassador
            </span>
          </h2>
          <p tw="mb-7 lg:mb-12">
            View Diana&apos;s story. Then, watch as Diana and her sleep
            specialist, Dr. Gerard J. Meskill, guide you through their
            conversations about Idiopathic Hypersomnia symptoms and XYWAV.
          </p>
          <VideoGallery
            videos={videoGalleryDiana}
            location={location}
            sectionId="diana"
            currentGallery={currentGallery}
            setCurrentGallery={setCurrentGallery}
            galleryId="3"
          />
        </Container>
      </Section>

      {/* Call out */}
      <Callout
        heading="Stay in the loop!"
        cta={{
          text: "SIGN UP NOW",
          url: "/idiopathic-hypersomnia/stay-connected/",
        }}
      >
        <>
          <p tw="xl:mb-8 sm:mb-5">
            We&apos;re here to support you with information on Idiopathic
            Hypersomnia, starting XYWAV, tips for talking with your doctor,
            JazzCares, and more. Join our email list today.
          </p>
        </>
      </Callout>

      {/* share story */}
      <section tw="bg-lilac">
        <div tw="md:(flex items-center h-[280px]) lg:(h-[320px]) xl:(relative h-[455px] max-w-[1400px] mx-auto)">
          <Container grid>
            <div tw="md:(max-w-[280px] grid content-center col-span-5 order-1 h-[327px]) lg:(h-[320px]) xl:(max-w-[unset] col-span-5 h-[455px])">
              <h2
                css={[
                  Heading2,
                  tw`text-center my-[25px] text-5xl xl:(text-left)`,
                ]}
              >
                Have a XYWAV{" "}
                <span tw="whitespace-nowrap md:whitespace-normal">
                  story to&nbsp;share?
                </span>
              </h2>
              <p tw="mb-[15px] text-center xl:(text-left mb-[25px] max-w-[372px])">
                Your experience with XYWAV could make a difference for others
                struggling with Idiopathic Hypersomnia.
              </p>
              <div tw="text-center my-[25px] md:(text-left mt-0)">
                <PrimaryCta url="mailto:ShareMyStory@Jazzpharma.com">
                  Email your story
                </PrimaryCta>
              </div>
            </div>
          </Container>
          <div tw="relative md:(absolute right-0 grid grid-cols-12 h-[280px]) lg:(h-[320px]) xl:(grid-cols-3 h-[455px])">
            <GatsbyImage
              image={supImage}
              alt=""
              tw="relative md:(col-span-8 col-start-5) xl:(ml-0 col-start-2)"
            />
          </div>
        </div>
      </section>

      <LearnMore ih colour="purple" ctas={learnMoreCtas} />
    </Layout>
  )
}

const learnMoreCtas = [
  {
    text: "Explore financial assistance options for qualified patients",
    url: "/idiopathic-hypersomnia/jazzcares-for-xywav/",
  },
  {
    text: "Learn about getting XYWAV",
    url: "/idiopathic-hypersomnia/getting-xywav/",
  },
]

const downloadDataTop = [
  {
    title: "Talking With Your Doctor Quiz",
    description:
      "Take a short quiz that can help create a list of the ways Idiopathic Hypersomnia could be impacting you—then download and bring it with you to your next visit.",
    link: {
      url: "/idiopathic-hypersomnia/talking-to-your-doctor/",
      label: "Take the Quiz",
    },
  },
  {
    title: "XYWAV Idiopathic Hypersomnia Brochure",
    description:
      "Find important information about XYWAV, including what it is, what to know about your treatment experience, and much more.",
    pdf: "/pdf/XYWAV_IH_Brochure.pdf",
  },
]

const downloadDataBottom = [
  {
    title: "Epworth Sleepiness Scale (ESS)",
    description:
      "The ESS can help you measure your level of excessive daytime sleepiness (EDS). Be sure to share your results with your doctor.",
    pdf: "/pdf/ESS_Downloadable_PDF.pdf",
  },
  {
    title: "Idiopathic Hypersomnia Severity Scale (IHSS)",
    description:
      "The IHSS can help you measure the frequency and severity of your Idiopathic Hypersomnia symptoms. Be sure to share your results with your doctor.",
    pdf: "/pdf/IHSS_Downloadable_PDF.pdf",
  },
]

const callout = {
  heading: "Live & on-demand webinars",
  cta: {
    text: "Go to webinars",
    url: "https://www.jazzwebinars.com/xywav-idiopathic-hypersomnia?tab=ondemand",
    external: true,
  },
}

export default PatientResourcesPage

export const query = graphql`
  query {
    image: file(relativePath: { eq: "patient-resources-ih/hero.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 800
          formats: [AUTO, WEBP, AVIF]
          placeholder: NONE
        )
      }
    }
    vidJennie1: file(
      relativePath: { eq: "video-thumbnails/patient-jennie-ih-diagnosis.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidJennie1Small: file(
      relativePath: { eq: "video-thumbnails/patient-jennie-ih-diagnosis.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidJennie2: file(
      relativePath: { eq: "video-thumbnails/patient-jennie-starting-xywav.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidJennie2Small: file(
      relativePath: { eq: "video-thumbnails/patient-jennie-starting-xywav.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidJennie3: file(
      relativePath: {
        eq: "video-thumbnails/patient-jennie-nighttime-routine.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidJennie3Small: file(
      relativePath: {
        eq: "video-thumbnails/patient-jennie-nighttime-routine.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidJennie4: file(
      relativePath: { eq: "video-thumbnails/patient-jennie-jazzcares.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidJennie4Small: file(
      relativePath: { eq: "video-thumbnails/patient-jennie-jazzcares.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidDiana1: file(
      relativePath: { eq: "video-thumbnails/patient-diana-xywav-story.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidDiana1Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-xywav-story.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidDiana2: file(
      relativePath: { eq: "video-thumbnails/patient-diana-ih-symptoms.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidDiana2Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-ih-symptoms.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidDiana3: file(
      relativePath: { eq: "video-thumbnails/patient-diana-sleep-inertia.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidDiana3Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-sleep-inertia.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidDiana4: file(
      relativePath: { eq: "video-thumbnails/patient-diana-eds.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidDiana4Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-eds.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidDiana5: file(
      relativePath: { eq: "video-thumbnails/patient-diana-treatment-goals.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidDiana5Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-treatment-goals.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    vidDiana6: file(
      relativePath: { eq: "video-thumbnails/patient-diana-xywav-benefits.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    vidDiana6Small: file(
      relativePath: { eq: "video-thumbnails/patient-diana-xywav-benefits.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 267, placeholder: NONE)
      }
    }
    myWavVidThumb: file(
      relativePath: { eq: "video-thumbnails/mywav-video-thumbnail.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670, placeholder: NONE)
      }
    }
    collageDesktop: file(
      relativePath: { eq: "patient-resources-ih/suc-collage-2.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 905, placeholder: NONE)
      }
    }
    collageMobile: file(
      relativePath: { eq: "patient-resources-ih/suc-collage-2-mobile.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 767, placeholder: NONE)
      }
    }
  }
`
